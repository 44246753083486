import {ApplicationConstants, ErrorTypes} from '../../constants/application.constants';
import {FormControl} from '@angular/forms';
import {
  isNullOrUndefined,
  isStringNullUndefinedOrEmpty,
  isStringNullUndefinedOrEmptyWithTrim
} from '../utility-functions/utility-functions';

export class DateUtility {

  public static buildServerDateFromJsDate(jsDate: Date): string {
    // SERVER FORMAT --> "2018-03-20T20:56:38.739"
    const numericMonth = jsDate.getMonth() + 1;
    const month = (numericMonth.toString().length === 1) ? `0${numericMonth}` :  numericMonth;
    const date = (jsDate.getDate().toString().length === 1) ? `0${jsDate.getDate()}` :  jsDate.getDate();
    const year = jsDate.getFullYear();
    const hours = (jsDate.getHours().toString().length === 1) ? `0${jsDate.getHours()}` :  jsDate.getHours();
    const minutes = (jsDate.getMinutes().toString().length === 1) ? `0${jsDate.getMinutes()}` :  jsDate.getMinutes();
    const seconds = (jsDate.getSeconds().toString().length === 1) ? `0${jsDate.getSeconds()}` :  jsDate.getSeconds();
    let milliseconds = jsDate.getMilliseconds().toString();
    while (milliseconds.length < 3) {
      milliseconds = '0' + milliseconds;
    }
    return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  public static buildDateFromDateString(date: string | Date): Date | null {
    // change format from YYYY-MM-DD to YYYY/MM/DD. Otherwise the parse will offset due to the timezone
    if (date instanceof Date) { return date; }
    if (isNullOrUndefined(date) || isStringNullUndefinedOrEmpty(date)) {
      return null;
    }
    const dateStringFormatted = (date.length > 10) ? date : date.replace(/-/g, '/');
    const dateValue = Date.parse(dateStringFormatted);
    // Date.parse will return NaN if it's not valid - return null
    if (isNaN(dateValue)){
      return null;
    }
    else {
      // convert the date value to a Date object
      return new Date(dateValue);
    }
  }

  public static buildYyyyMmDdDateFromDate(date: Date | string): string {
    if (isNullOrUndefined(date) || (typeof(date) === 'string' && isStringNullUndefinedOrEmpty(date))) {
      return '';
    }
    // return --> "2018-03-20"
    date = (typeof(date) === 'string') ? new Date(date) : date;
    const numericMonth = date.getMonth() + 1;
    const month = (numericMonth.toString().length === 1) ? `0${numericMonth}` :  numericMonth;
    const day = (date.getDate().toString().length === 1) ? `0${date.getDate()}` :  date.getDate();
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  public static buildMmDdYyyyDateFromDate(date: Date | string): string {
    if (isNullOrUndefined(date) || (typeof(date) === 'string' && isStringNullUndefinedOrEmpty(date))) {
      return '';
    }
    // return --> "2018-03-20"
    date = (typeof(date) === 'string') ? new Date(date) : date;
    const numericMonth = date.getMonth() + 1;
    const month = (numericMonth.toString().length === 1) ? `0${numericMonth}` :  numericMonth;
    const day = (date.getDate().toString().length === 1) ? `0${date.getDate()}` :  date.getDate();
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }

  public static buildFriendlyDateFromJsDate(date: Date | string): string {
    if (isNullOrUndefined(date)) {
      return '';
    }
    if (typeof(date) === 'string') {
      if (isNaN(Date.parse(date))) {
        return '';
      }

      date = new Date(date +
        (date.length > 10 ? '' : date.charAt(4) === '-' ? 'T12:00:00.000-0700' : ' 12:00:00 GMT-0700'));
    }
    const numericMonth = date.getMonth() + 1;
    const month = (numericMonth < 10 ? '0' :  '') + numericMonth;
    const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
    const year = '' + date.getFullYear();
    return [month, day, year].join('/');
  }

  public static formatRawDateToMmDdYyyy(date: string): string {
    const strictDateRegex = ApplicationConstants.mmDdYyyyDateRegex;
    const oneDigitMonthDayRegex = ApplicationConstants.singleDigitMonthOrDayDateRegex;
    if (isStringNullUndefinedOrEmpty(date)) {
      return '';
    }
    if (!strictDateRegex.test(date) && oneDigitMonthDayRegex.test(date)) {
      const dateSplit = date.split('/');
      const month = dateSplit[0].length === 1 ? '0' + dateSplit[0] : dateSplit[0];
      const day = dateSplit[1].length === 1 ? '0' + dateSplit[1] : dateSplit[1];
      const year = dateSplit[2];
      return month + '/' + day + '/' + year;
    }
    return date;
  }

  public static isValidDate(date: string | Date): boolean {
    if (!date) {
      return false;
    }
    let date_regex = ApplicationConstants.mmDdYyyyDateRegex;
    if (typeof(date) === 'string' && !date_regex.test(date)){
      return false;
    }
    let isValidDateCoercion: boolean = true;
    if (typeof(date) === 'string') {
      const day: string = date.split('/')[1];
      date = new Date(date);
      isValidDateCoercion = date.getDate() === parseInt(day);
    }
    return !isNaN(date.valueOf()) && isValidDateCoercion;
  }

  public static hasDateFormatAndValidityError(control: FormControl): boolean {
    const invalidDateFormat = control.hasError(ErrorTypes.InvalidDateFormat);
    const invalidDate = control.hasError(ErrorTypes.InvalidDate);
    const futureDate = control.hasError(ErrorTypes.NoFutureDate);
    return invalidDateFormat || invalidDate || futureDate;
  }

  /**
   * Partial DOB for member will come in from the API in format xxxxMmDd where xxxx is masked year
   * Returns the passed in partialDOB in mm/dd/xxxx format
   * @param {string} partialDOB
   * @returns {string}
  */
  public static formatMemberPartialDOB(partialDOB: string): string {
    const year = partialDOB.slice(0,4);
    const month = partialDOB.slice(4,6);
    const day = partialDOB.slice(6,8);
    return month + '/' + day + '/' + year;
  }

  public static formatApiDateStringToPresentationDate(date: string): string {
    const apiFormattedDateRegex: RegExp = new RegExp(ApplicationConstants.yyyymmddDateRegex);
    if (isStringNullUndefinedOrEmpty(date) || !apiFormattedDateRegex.test(date)) {
      return undefined;
    }

    const tokenizedDate: string[] = date.split('-');
    return [
      tokenizedDate[1],
      tokenizedDate[2],
      tokenizedDate[0]
    ].join('/');
  }

  public static getTwoYearAgoDate() {
    {
      const twoYearAgo = new Date();
      twoYearAgo.setFullYear(twoYearAgo.getFullYear() - 2);
      twoYearAgo.setDate(twoYearAgo.getDate()-1);
      return twoYearAgo;
    }
  }

  public static buildMonthDateFromToRange(date: Date): string {
    if (!isNullOrUndefined(date)) {
      const numericMonth = date.getMonth() + 1;
      const month = (numericMonth.toString().length === 1) ? `0${numericMonth}` :  numericMonth;
      const year = date.getFullYear();
      const lastDayOfTheMonthDate = this.getLastDayOfMonth(year, date.getMonth());
      return `${year}-${month}-01` + ApplicationConstants.comma + this.buildYyyyMmDdDateFromDate(lastDayOfTheMonthDate);
    }
    return undefined;
  }

  public static getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0);
  }

  public static isStringDateInFuture(stringDate: string): boolean {
    if (!isStringNullUndefinedOrEmptyWithTrim(stringDate)) {
      const todayDate: Date = new Date();
      const serviceStartDateToDate: Date = DateUtility.buildDateFromDateString(stringDate);
      if (serviceStartDateToDate > todayDate) {
        return true;
      }
    }
    return false;
  }
}

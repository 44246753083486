/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
// import 'core-js/es6/object';
// import 'core-js/es6/array';
// import 'core-js/es6/symbol';
// import 'core-js/es6/function';
// import 'core-js/es6/parse-int';
// import 'core-js/es6/parse-float';
// import 'core-js/es6/number';
// import 'core-js/es6/math';
// import 'core-js/es6/string';
// import 'core-js/es6/date';
// import 'core-js/es6/regexp';
// import 'core-js/es6/map';
// import 'core-js/es6/weak-map';
// import 'core-js/es6/set';
// import 'core-js/es6/promise';
// import 'core-js/es7/array';
// import 'core-js/es7/object';
// import 'core-js/es7/promise';
// import 'core-js/es7/promise';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es6/reflect';


/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
// import 'core-js/es7/reflect';


/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

// (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
// (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

/*
* in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
* with the following flag, it will bypass `zone.js` patch for IE/Edge
*/
// (window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone'; // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */


/***** START - VSP-SPECIFIC POLYFILLS *****/

/**
 * Adding a deepClone function to the native Object class
 */
import {isNullOrUndefined} from './app/common/utility';
declare global {
  interface Object {
    deepClone(object: any): any;
    flatten(object: any): any;
  }
}
Object.defineProperty(Object.prototype, 'deepClone', {
  value: function(objectToClone) {
    // Turns any date strings into Date objects for the passed in object
    const convertDateStrings = function(objectToSearch: any): any {
      // Date strings will be in the following format (result of calling JSON.stringify() on a Date object): "2018-11-07T18:02:59.790Z"
      const isDateString = (stringToSearch: string): boolean => {
        if (typeof(stringToSearch) !== 'string') { return false; }
        if (stringToSearch.length === 24 && (stringToSearch.charAt(23) === 'z' || stringToSearch.charAt(23) === 'Z') && !isNaN(Number(stringToSearch.charAt(22)))) { return true; }
      };
      for (const key in objectToSearch) {
        const value = objectToSearch[key];
        if (isDateString(value)) {
          objectToSearch[key] = new Date(value);
        }
        if (typeof(objectToSearch[key]) === 'object' && objectToSearch[key] !== null) {
          let innerObject = objectToSearch[key];
          innerObject = convertDateStrings(innerObject);
        }
      }
      return objectToSearch;
    };
    if (!isNullOrUndefined(objectToClone)) {
      let clonedObject = JSON.parse(JSON.stringify(objectToClone));
      clonedObject = convertDateStrings(clonedObject);
      return clonedObject;
    }
    return null;
  },
  enumerable: false,
});

/**
 * Object flattening prototype, used for nested object structures.
 */
Object.defineProperty(Object.prototype, 'flatten', {
  value: (objectToFlatten: any) => {
    const flattenedObject = {};

    // Loop through each key on the object and assign it to our return object
    Object.keys(objectToFlatten).forEach((key: any) => {
      if (typeof objectToFlatten[key] === 'object' && !isNullOrUndefined(objectToFlatten[key])) {
        // Recursively assign nested object properties until we reach the root of the node
        Object.assign(flattenedObject, Object.flatten(objectToFlatten[key]));
      } else {
        // Append the object property to the return object once we've found a non-object primitive value
        flattenedObject[key] = objectToFlatten[key];
      }
    });

    return flattenedObject;
  },
  enumerable: false
});

/***** END - VSP-SPECIFIC POLYFILLS *****/

import {Component, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface LoadingModalOptions {
  modalMessageText: string;
}

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingModalComponent {
  id: string;

  constructor(
    public dialogRef: MatDialogRef<LoadingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoadingModalOptions
  ) {}

}
